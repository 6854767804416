<template>
  <TheLoadingSpinner :is-visible="isLoading" is-fullscreen />
  <article aria-label="Tarifrechner" class="tariff-calculator container-type-inline">
    <TariffCalculatorTabs
      :initial-tab-index="initialTabIndex"
      :tabs="displayTabs"
      @set-active-tab="setActiveTab"
    />
    <div class="p-4 background-light-teal">
      <FormKit
        id="tariffCalculator"
        type="form"
        :actions="false"
        @submit="fetchAndCalculate"
      >
        <div class="tab-radio-customer-type--wrapper">
          <div class="tab-radio--wrapper">
            <!-- Reset on radio (type) change -->
            <FormKit
              v-if="activeTabRadios && activeTabRadios.length > 1"
              :key="activeTabRadios[0].value"
              outer-class="mt-0"
              type="radio"
              :options="activeTabRadios"
              @input="() => resetConsumptions()"
              @node="setActiveTariffChoiceRadioGroupNode"
            />
          </div>
          <div v-if="calculator.canSwitchBusinessCustomer" class="customer-type--toggle">
            <CustomerTypeField
              :disabled="Boolean(initialCustomerType)"
              :value="customerType"
              @update="setCustomerType"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 my-3">
            <div class="h3">
              {{
                currentActiveTariffName
                  ? `Wirklich ${currentActiveTariffName} berechnen`
                  : ""
              }}
            </div>
          </div>
        </div>
        <div v-if="isHeatPumpBooking || isNightStorageHeatingBooking">
          <div class="h4">Dein Zählertyp</div>
          <FormKit
            v-model="additionalMeter"
            outer-class="my-0"
            type="checkbox"
            :label="`Extra-Zähler für ${currentActiveTariffName}`"
          />
          <FormKit
            v-model="doubleMeter"
            type="checkbox"
            label="Zwei-Tarifzähler (HT/NT-Zähler misst zwei Zählerstände)"
          />
        </div>
        <div class="show-and-pick-content" :class="{ 'dual-booking': isDualBooking }">
          <div class="left">
            <div class="postal-code--wrapper" :class="{ 'dual-booking': isDualBooking }">
              <div class="headline h4">1. Deine Postleitzahl</div>
              <FormKit
                v-model="finalPostalCode"
                outer-class="mt-3"
                input-class="number-input-without-buttons"
                name="zipCode"
                type="number"
                autocomplete="postal-code"
                validation="required|number|length:5,5"
                aria-label="Postleitzahl"
                placeholder="Postleitzahl"
                :floating-label="false"
                :validation-messages="{
                  length: zipCodeValidationMessage,
                  number: zipCodeValidationMessage,
                  required: zipCodeValidationMessage,
                }"
              />
            </div>
          </div>
          <div class="center">
            <div
              v-if="isSinglePowerBooking || isDualBooking"
              :class="{ 'dual-booking': isDualBooking }"
            >
              <div class="headline h4">2. Dein Stromverbrauch</div>
              <ConsumptionPicker
                v-if="
                  (isSimplePowerBooking && isPrivateCustomer) ||
                  (isNightStorageHeatingBooking && !doubleMeter) ||
                  (isHeatPumpBooking && !doubleMeter)
                "
                :type="isSimplePowerBooking ? 'simple-power' : 'power'"
                :consumption="showPowerHighConsumption"
                :show-vertically="isDualBooking"
                @update-consumption="setPowerConsumption"
              />
              <ConsumptionEmployeeCount
                v-if="isSimplePowerBooking && isCompanyCustomer"
                :power-consumption="showPowerHighConsumptionCompany"
                :factor="employeeCountFactor"
                :show-vertically="isDualBooking"
                @update-power-consumption="(c) => setPowerConsumptionCompany(c)"
              />
              <ConsumptionCarPowerPlus
                v-if="isCarPlusBooking"
                :power-consumption="showPowerHighConsumption"
                @update-power-consumption="(c) => setPowerConsumption(c)"
              />
              <ConsumptionPowerHighLow
                v-if="
                  isDoubleBooking ||
                  (isNightStorageHeatingBooking && doubleMeter) ||
                  (isHeatPumpBooking && doubleMeter)
                "
                :power-consumption-high="showPowerHighConsumption"
                :power-consumption-low="showPowerLowConsumption || 0"
                @update-power-consumption="setHighAndLowPowerConsumption"
              />
            </div>
            <div v-if="isSingleGasBooking" :class="{ 'dual-booking': isDualBooking }">
              <TheHeadline size="h4" type="h3" class="headline">
                {{ isDualBooking ? "3" : "2" }}. Dein Gasverbrauch
              </TheHeadline>
              <ConsumptionPicker
                type="gas"
                :consumption="showGasConsumption"
                :show-vertically="isDualBooking"
                @update-consumption="setGasConsumption"
              />
            </div>
          </div>
          <div class="right" :class="{ 'dual-booking': isDualBooking }">
            <div v-if="isDualBooking">
              <TheHeadline size="h4" type="h3">
                {{ isDualBooking ? "3" : "2" }}. Dein Gasverbrauch
              </TheHeadline>
              <ConsumptionPicker
                type="gas"
                :consumption="showGasConsumption"
                :show-vertically="isDualBooking"
                @update-consumption="setGasConsumption"
              />
            </div>

            <FormsSubmitButton
              v-else
              no-margin
              color="teal"
              label="Preis berechnen"
              class="enforce-light-mode"
            />
          </div>
        </div>
        <ShowAndPickNotice
          :is-gas-notice-active="isGasNoticeActive"
          :is-car-booking="isCarBooking"
          :is-car-plus-booking="isCarPlusBooking"
        />
        <div class="d-flex justify-content-center">
          <FormsSubmitButton
            v-if="isDualBooking"
            no-margin
            color="teal"
            label="Preis berechnen"
            class="enforce-light-mode"
          />
        </div>
      </FormKit>
    </div>

    <div v-if="hasErrors" aria-live="polite" class="background-light-teal p-4 my-5">
      <p class="my-3">Wir konnten keinen Tarif zu deiner PLZ finden.</p>
    </div>

    <div
      v-else-if="showOwnTariffQuotaMessage"
      aria-live="polite"
      class="background-light-teal p-4 my-5"
    >
      <p class="my-3">
        <span class="d-block"
          >Wirklich Eigenstrom gibt's nur bis max. 10.000 kWh/Jahr.</span
        >
        <TheLinkButton color="teal" appearance="filled" to="/oekostrom/" class="mt-2">
          Weitere Ökostrom-Tarife zeigen
        </TheLinkButton>
      </p>
    </div>

    <section
      v-else-if="areResultsShown"
      id="tarifliste"
      aria-label="Suchergebnisliste"
      aria-live="polite"
      class="container-type-inline background-light-teal my-4"
    >
      <div>
        <div v-if="isSinglePowerBooking || isDualBooking">
          <TariffList
            v-if="displayPowerTariffs"
            tariffs-type="power"
            :tariffs="displayPowerTariffs"
            :selectable-tariffs="
              activeTab?.tariffs?.filter((t) => t.tariffType === 'power') || []
            "
            :is-dual-booking="isDualBooking"
            :customer-type="customerType"
            :target-gas-consumption="pickedGasConsumption"
            :target-power-low-consumption="pickedPowerLowConsumption"
            :target-power-high-consumption="pickedPowerHighConsumption"
            :double-meter="doubleMeter"
            :is-admin="isAdmin"
            @choose-tariff="chooseTariff"
          />
        </div>

        <TariffListSeperator v-if="isDualBooking" />

        <div
          v-if="isSingleGasBooking || isDualBooking"
          :class="{ 'mt-4': isDualBooking }"
        >
          <TariffList
            v-if="displayGasTariffs"
            tariffs-type="gas"
            :tariffs="displayGasTariffs"
            :selectable-tariffs="
              activeTab?.tariffs?.filter((t) => t.tariffType === 'gas') || []
            "
            :customer-type="customerType"
            :is-dual-booking="isDualBooking"
            :target-gas-consumption="pickedGasConsumption"
            :target-power-low-consumption="pickedPowerLowConsumption"
            :target-power-high-consumption="pickedPowerHighConsumption"
            :double-meter="doubleMeter"
            :is-admin="isAdmin"
            @choose-tariff="chooseTariff"
          />
        </div>

        <TariffListSeperator v-if="isDualBooking" />

        <div v-if="isDualBooking" id="advance-button" class="text-center mt-5">
          <TheButton
            appearance="filled"
            position="block"
            color="teal"
            :disabled="!selectedPowerContract || !selectedGasContract"
            style="width: 300px"
            @click="chooseTariffAndAdvance"
          >
            Mit deiner Auswahl weiter
          </TheButton>
        </div>

        <div class="p-3 pt-0">
          <hr />
          <TariffCalculatorShareLink :copied="copied" @click="copyShareLink" />
        </div>
      </div>
    </section>
  </article>

  <!-- Too high consumption modal -->
  <TheModal ref="modal" title="Ab 100.000 kWh gibt's immer ein persönliches Angebot.">
    <p>
      Ruf
      <a :href="tel(config.contact.phone.general)">
        {{ config.contact.phone.general }}
      </a>
      an oder
      <a :href="config.contact.form.quoteForm" target="_blank">
        klicke hier und fülle unser Formular aus</a
      >.
    </p>
    <p>Bis gleich!</p>
  </TheModal>
</template>

<script lang="ts" setup>
import type { FormKitNode } from "@formkit/core";
import { storeToRefs } from "pinia";
import ShowAndPickNotice from "./ShowAndPickNotice.vue";
import CustomerTypeField from "./form-fields/CustomerTypeField.vue";
import ConsumptionCarPowerPlus from "~/components/02_molecules/tariff-calculator/ConsumptionCarPowerPlus.vue";
import ConsumptionEmployeeCount from "~/components/02_molecules/tariff-calculator/ConsumptionEmployeeCount.vue";
import ConsumptionPowerHighLow from "~/components/02_molecules/tariff-calculator/ConsumptionPowerHighLow.vue";
import ConsumptionPicker from "~/components/02_molecules/tariff-calculator/ConsumptionPicker.vue";
import TariffList from "~/components/02_molecules/tariff-calculator/TariffList.vue";
import TariffListSeperator from "~/components/01_atoms/tariff-calculator/TariffListSeperator.vue";
import TariffCalculatorShareLink from "~/components/01_atoms/tariff-calculator/TariffCalculatorShareLink.vue";
import TariffCalculatorTabs from "~/components/02_molecules/tariff-calculator/TariffCalculatorTabs.vue";
import {
  useTrackChooseAndAdvance,
  useTrackViewItem,
} from "~/composables/useCheckoutTracking";
import { defaultCalculatorConfig } from "~/utils/tariff-calculator/defaultCalculatorConfig";
import useReferralCode from "~/composables/useReferralCode";
import config, { adminAreaPrefix, customerAreaPrefix } from "~/config";
import type { PublicTariffWithSummarizedPriceRanges as AdminTariff } from "~/src/generated-sources/admin";
import {
  TariffType,
  type PublicTariffWithSummarizedPriceRanges as PublicTariff,
  type PublicTariffSubTypeEnum,
  CustomerType,
} from "~/src/generated-sources/public";
import {
  gasProductGroups,
  type Calculator,
  type CalculatorTab,
  type CalculatorTariffType,
  type NewContract,
  type ProductGroup,
  simplePowerProductGroups,
} from "~/types/shared/calculator";
import type { TariffCalculatorShareLinkAttributes } from "~/utils/tariff-calculator/shareLinkShortener";
import TheHeadline from "~/components/01_atoms/TheHeadline.vue";
import TheLinkButton from "~/components/01_atoms/TheLinkButton.vue";
import TheButton from "~/components/01_atoms/TheButton.vue";
import {
  shareLinkDecryptor,
  shareLinkShortener,
} from "~/utils/tariff-calculator/shareLinkShortener";
import { filterTariffs } from "~/utils/tariff-calculator/tariffFiltering";
import FormsSubmitButton from "~/components/02_molecules/forms/SubmitButton.vue";
import TheModal from "~/components/01_atoms/TheModal.vue";
import type { ContractType } from "~/types/shared/contract-type";
import type { FetchTariffsCallback } from "~/types/shared/fetch-tariffs-callback";
import { useCustomerStore } from "~/stores/customer";
import { useAdminImpersonificationStore } from "~/stores/admin-impersonification";
import { useOrderStore } from "~/stores/order";
import tel from "~/utils/tel";
import TheLoadingSpinner from "~/components/01_atoms/TheLoadingSpinner.vue";

const route = useRoute();
const { copy, copied } = useClipboard();
const { getReferralCode } = useReferralCode();
const isLoading = ref(false);
const modal = ref<InstanceType<typeof TheModal>>();

/**
 * Calculates the list of radio options that shall be shown for the current tab of the tariff calculator.
 * Those tabs are called "ProductGroup" since they might include special stuff like "Ökostrom + Ökogas".
 * All valid tariffTypes are noted down for each radio option so that it can be used for filtering the
 * tariffs returned by the backend.
 *
 * @param tariffTypes The tariffTypes that are configured for the current tab.
 * @returns The list of radio options that shall be shown for the current tab.
 */
const generateCurrentTabProductGroups = (
  tariffTypes: CalculatorTariffType[],
): ProductGroup[] => {
  const availableRadioOptions: ProductGroup[] = [];

  const classicPowerTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "classic",
  );
  const flexPowerTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "classicFlex",
  );
  const classicPowerAndFlexTariffTypes = [
    ...classicPowerTariffTypes,
    ...flexPowerTariffTypes,
  ];
  if (classicPowerAndFlexTariffTypes.length) {
    availableRadioOptions.push({
      label: "Ökostrom",
      value: "classicPower",
      allowedCalculatorTariffTypes: classicPowerAndFlexTariffTypes,
    });
  }

  const heatPumpTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "heatPump",
  );
  if (heatPumpTariffTypes.length) {
    availableRadioOptions.push({
      label: "Wärmepumpenstrom",
      value: "heatPump",
      allowedCalculatorTariffTypes: heatPumpTariffTypes,
    });
  }

  const nightStorageHeatingTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "nightStorageHeating",
  );
  if (nightStorageHeatingTariffTypes.length) {
    availableRadioOptions.push({
      label: "Nachtspeicherstrom",
      value: "nightStorageHeating",
      allowedCalculatorTariffTypes: nightStorageHeatingTariffTypes,
    });
  }

  const doubleTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "double",
  );
  if (doubleTariffTypes.length) {
    availableRadioOptions.push({
      label: "HT/NT-Strom",
      value: "double",
      allowedCalculatorTariffTypes: doubleTariffTypes,
    });
  }

  const carPowerTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "carPower",
  );
  if (carPowerTariffTypes.length) {
    availableRadioOptions.push({
      label: "Autostrom",
      value: "carPower",
      allowedCalculatorTariffTypes: carPowerTariffTypes,
    });
  }

  const carPowerPlusTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "carPowerPlus",
  );
  if (carPowerPlusTariffTypes.length) {
    availableRadioOptions.push({
      label: "Autostrom Plus",
      value: "carPowerPlus",
      allowedCalculatorTariffTypes: carPowerPlusTariffTypes,
    });
  }

  const tenantPowerTariffTypes = tariffTypes.filter(
    (t) =>
      (t.tariffType === "power" && t.tariffSubType === "tenantPower") ||
      (t.tariffType === "power" && t.tariffSubType === "tenantPowerShadow"),
  );
  if (tenantPowerTariffTypes.length) {
    availableRadioOptions.push({
      label: "Mieterstrom",
      value: "tenantPower",
      allowedCalculatorTariffTypes: tenantPowerTariffTypes,
    });
  }

  const ownTariffTypes = tariffTypes.filter(
    (t) => t.tariffType === "power" && t.tariffSubType === "own",
  );
  if (ownTariffTypes.length) {
    availableRadioOptions.push({
      label: "Eigenstrom",
      value: "own",
      allowedCalculatorTariffTypes: ownTariffTypes,
    });
  }

  const classicGasTariffTypes = tariffTypes.filter((t) => t.tariffType === "gas");
  if (classicGasTariffTypes.length) {
    availableRadioOptions.push({
      label: "Ökogas",
      value: "classicGas",
      allowedCalculatorTariffTypes: classicGasTariffTypes,
    });
  }

  // TODO: Previously only Ökostrom Normal was used here and not also flex. why?
  if (classicPowerAndFlexTariffTypes.length && classicGasTariffTypes.length) {
    availableRadioOptions.push({
      label: "Ökostrom + Ökogas",
      value: "classicPower+classicGas",
      allowedCalculatorTariffTypes: [
        ...classicPowerAndFlexTariffTypes,
        ...classicGasTariffTypes,
      ],
    });
  }

  return availableRadioOptions;
};

const maxConsumption = 99999;
const zipCodeValidationMessage = "Bitte gib eine gültige Postleitzahl ein";

const props = withDefaults(
  defineProps<{
    advanceUrl?: string;
    calculator?: Calculator;
    isAdmin?: boolean;
    initialZipCode?: string;
    fetchTariffsCallback: FetchTariffsCallback;
  }>(),
  {
    advanceUrl: "/bestellen/dateneingabe",
    initialZipCode: "",
    calculator: () => defaultCalculatorConfig,
  },
);

const displayTabs = computed(() =>
  props.isAdmin
    ? props.calculator.tabs
    : props.calculator.tabs.filter((t) => !t.isAdminOnly),
);

const { $config } = useNuxtApp();

const { customer } = storeToRefs(useCustomerStore());
const { customer: customerAsAdmin } = storeToRefs(useAdminImpersonificationStore());

const orderStore = useOrderStore();

const additionalMeter = ref(false);
const doubleMeter = ref(false);

// Used for the first, "browse" step
const showPowerHighConsumption = ref(2400);
const showPowerHighConsumptionCompany = ref(8500);
const employeeCountFactor = showPowerHighConsumptionCompany.value / 30;
const showPowerLowConsumption = ref<number | null>(null);
const showPowerLowConsumptionCompany = ref<number | null>(null);
const showGasConsumption = ref(5000);

// Used for the second, "choose tariff" step (not reactive)
const pickedPowerHighConsumption = ref(0);
// If pickedPowerLowConsumption is null, no HT/NT meter is used
const pickedPowerLowConsumption = ref<number | null>(null);
const pickedGasConsumption = ref(0);

const selectedPowerContract = ref<NewContract>();
const selectedGasContract = ref<NewContract>();

const hasErrors = ref(false);
const showOwnTariffQuotaMessage = ref(false);
const ownTariffMaxConsumption = 10000;
const areResultsShown = ref(false);
const finalPostalCode = ref<string>(props.initialZipCode || "");

const displayPowerTariffs = ref<PublicTariff[]>();
const displayGasTariffs = ref<PublicTariff[]>();

const getTariffTypeForPowerTariffs = (subType: PublicTariffSubTypeEnum): ContractType => {
  if (subType === "tenantPower" || subType === "tenantPowerShadow") {
    return subType;
  }
  return "power";
};

const scrollToTariffs = (type: TariffType) => {
  const tariffsToScrollTo = type === TariffType.Power ? "#power-tariffs" : "#gas-tariffs";
  document.querySelector(tariffsToScrollTo)?.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

const scrollToAdvanceButton = () => {
  document.querySelector("#advance-button")?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "end",
  });
};

const chooseTariff = (tariff: PublicTariff) => {
  if (tariff.type === "power") {
    selectedPowerContract.value = {
      annualConsumptionValueHigh: pickedPowerHighConsumption.value.toString(),
      annualConsumptionValueLow: pickedPowerLowConsumption.value?.toString() || undefined,
      tariff,
      tariffHistory: [{ tariffId: tariff.id }],
      tariffName: tariff.name,
      tariffSubType: tariff.subType,
      type: getTariffTypeForPowerTariffs(tariff.subType),
    };

    if (!selectedGasContract.value) {
      scrollToTariffs(TariffType.Gas);
    } else {
      scrollToAdvanceButton();
    }
  } else {
    selectedGasContract.value = {
      annualConsumptionValueHigh: pickedGasConsumption.value.toString(),
      tariff,
      tariffHistory: [{ tariffId: tariff.id }],
      tariffName: tariff.name,
      tariffSubType: tariff.subType,
      type: tariff.type,
    };

    if (!selectedPowerContract.value) {
      scrollToTariffs(TariffType.Power);
    } else {
      scrollToAdvanceButton();
    }
  }

  if (isDualBooking.value) {
    if (!props.isAdmin && activeTariffChoice.value) {
      const trackContract =
        tariff.type === "power" ? selectedPowerContract.value : selectedGasContract.value;

      if (!trackContract) {
        return;
      }

      useTrackTariffSelect({
        bookingType: customer ? "zubuchung" : "erstvertrag",
        consumption: orderStore.trackingInfo?.consumption,
        customerType:
          customer.value?.customerType || props.calculator.businessCustomerPreSelected
            ? CustomerType.Company
            : CustomerType.Private,
        coupon: getReferralCode().code,
        newContracts: [trackContract],
        postalCode: finalPostalCode.value,
        productGroup: orderStore.trackingInfo?.productGroup,
        tabTitle: orderStore.trackingInfo?.tabTitle || "Kein sichtbarer Tab",
        userLoggedIn: !!customer.value?.id,
      });
    }

    // Early return because for isDualBooking chooseTariffAndAdvance() is called on dedicated button click
    return;
  }

  chooseTariffAndAdvance();
};

/** The tab of the calculator that is currently active, e.g. "Klassiker" */
const activeTab = ref<CalculatorTab>();
/** The initial tab that will be opened upon initialization.  */
const initialTabIndex = ref(0);
/** The radio buttons of the calculator tab that are currently shown, e.g. "Ökostrom, Ökogas, Ökostrom+Ökogas" */
const activeTabRadios = ref<ProductGroup[]>();
/** The radio button of the calculator tab that is currently selected. */
const activeTariffChoice = ref<ProductGroup>();

/**
 * Reset consumptions to make sure that we don't have any leftovers,
 * e.g. doubleMeter params for classic tariffs.
 * @param resetStandardConsumptions If true, also reset the standard consumption values
 */
const resetConsumptions = (resetStandardConsumptions = false) => {
  if (resetStandardConsumptions) {
    showPowerHighConsumption.value = 2400;
    showPowerHighConsumptionCompany.value = 8500;
    showGasConsumption.value = 5000;
  }
  showPowerLowConsumption.value = null;
  showPowerLowConsumptionCompany.value = null;
};

watch(
  () => [
    activeTariffChoice.value,
    doubleMeter.value,
    additionalMeter.value,
    finalPostalCode.value,
  ],
  () => {
    // For now, just hide all tariffs after changing the tariff type.
    // TODO: Implement a reactive filtering so that no new fetching is needed.
    areResultsShown.value = false;
  },
);

const isSimplePowerBooking = computed(
  () =>
    activeTariffChoice.value &&
    simplePowerProductGroups.includes(activeTariffChoice.value.value),
);

const isGasBooking = computed(
  () =>
    activeTariffChoice.value && gasProductGroups.includes(activeTariffChoice.value.value),
);

const isGasNoticeActive = computed(() =>
  Boolean(isGasBooking.value && isTodaysDateBetween("2024-03-15", "2024-03-31")),
);

const isCarBooking = computed(() => activeTariffChoice.value?.value === "carPower");
const isCarPlusBooking = computed(
  () => activeTariffChoice.value?.value === "carPowerPlus",
);

const isDoubleBooking = computed(() => activeTariffChoice.value?.value === "double");

const isNightStorageHeatingBooking = computed(
  () => activeTariffChoice.value?.value === "nightStorageHeating",
);

const isHeatPumpBooking = computed(() => activeTariffChoice.value?.value === "heatPump");

const isSingleGasBooking = computed(
  () => activeTariffChoice.value?.value === "classicGas",
);

const isCompanyCustomer = computed(() => customerType.value === CustomerType.Company);
const isPrivateCustomer = computed(() => customerType.value === CustomerType.Private);

const isSinglePowerBooking = computed(
  () =>
    !!activeTariffChoice.value &&
    !isDualBooking.value &&
    [
      "classicPower",
      "heatPump",
      "nightStorageHeating",
      "double",
      "carPower",
      "carPowerPlus",
      "own",
      "tenantPower",
    ].includes(activeTariffChoice.value.value),
);

const isDualBooking = computed(
  () => activeTariffChoice.value?.value === "classicPower+classicGas",
);

const currentActiveTariffName = computed(() => {
  if (!activeTariffChoice.value?.value) {
    return "";
  }

  let currentProductGroupTitle = "";

  switch (activeTariffChoice.value?.value) {
    case "classicPower":
      currentProductGroupTitle = "Ökostrom";
      break;
    case "classicGas":
      currentProductGroupTitle = "Ökogas";
      break;
    case "classicPower+classicGas":
      currentProductGroupTitle = "Ökostrom plus Ökogas";
      break;
    case "heatPump":
      currentProductGroupTitle = "Wärmepumpenstrom";
      break;
    case "nightStorageHeating":
      currentProductGroupTitle = "Nachtspeicherstrom";
      break;
    case "double":
      currentProductGroupTitle = "HT/NT-Strom";
      break;
    case "carPower":
      currentProductGroupTitle = "Autostrom";
      break;
    case "carPowerPlus":
      currentProductGroupTitle = "Autostrom Plus";
      break;
    case "own":
      currentProductGroupTitle = "Eigenstrom";
      break;
    case "tenantPower":
      currentProductGroupTitle = "Mieterstrom";
      break;
  }

  // TODO: Retrieve activeRadios->activeTariffChoice label
  return currentProductGroupTitle.length ? currentProductGroupTitle : "";
});

// Reset low tariff consumption values if no double meter is present
watch(doubleMeter, () => {
  if (!doubleMeter.value) {
    showPowerLowConsumption.value = null;
  }
});

const setPowerConsumption = (newHighConsumption: number) => {
  showPowerHighConsumption.value = newHighConsumption;
};

const setHighAndLowPowerConsumption = (
  newHighConsumption: number,
  newLowConsumption: number | null = null,
) => {
  showPowerHighConsumption.value = newHighConsumption;
  showPowerLowConsumption.value = newLowConsumption;
  showPowerHighConsumptionCompany.value = newHighConsumption;
  showPowerLowConsumptionCompany.value = newLowConsumption;
};

const setPowerConsumptionCompany = (newPowerConsumptionCompany: number) => {
  showPowerHighConsumptionCompany.value = newPowerConsumptionCompany;
};

const setGasConsumption = (newGasConsumption: number) => {
  showGasConsumption.value = newGasConsumption;
};

const customerType = ref<CustomerType>(CustomerType.Private);
const setCustomerType = (type: CustomerType) => {
  customerType.value = type;
};

const setActiveTab = (tab: CalculatorTab) => {
  activeTab.value = tab;
  activeTabRadios.value = generateCurrentTabProductGroups(activeTab.value.tariffs);
  activeTariffChoice.value = activeTabRadios.value[0];

  // Reset on tab change
  resetConsumptions(true);

  // For now, just hide all tariffs after changing the tariff type.
  // TODO: Implement a reactive filtering so that no new fetching is needed.
  areResultsShown.value = false;
};

const setActiveTariffChoiceRadioGroupNode = (node: FormKitNode) => {
  if (node) {
    node.input(activeTariffChoice.value?.value);
    node.on("commit", ({ payload }) => {
      if (activeTab.value) {
        const tabs = generateCurrentTabProductGroups(activeTab.value.tariffs);
        activeTariffChoice.value = tabs.find((t) => t.value === payload);
      }
    });
  }
};

const initialCustomerType = props.isAdmin
  ? customerAsAdmin.value?.customerType
  : customer.value?.customerType;

const initializeCustomerType = () => {
  let type: CustomerType = CustomerType.Private;

  const isAllowedToSwitch = props.calculator.canSwitchBusinessCustomer;
  const isBusinessCustomerPreSelected = props.calculator.businessCustomerPreSelected;

  if (!isAllowedToSwitch) {
    type = isBusinessCustomerPreSelected ? CustomerType.Company : CustomerType.Private;
  } else if (!initialCustomerType) {
    if (
      isBusinessCustomerPreSelected ||
      orderStore.customerType === CustomerType.Company
    ) {
      type = CustomerType.Company;
    }
  } else if (initialCustomerType !== CustomerType.Private) {
    type = CustomerType.Company;
  }

  customerType.value = type;
};

onMounted(() => {
  if (orderStore.newCustomerZipCode) {
    finalPostalCode.value = orderStore.newCustomerZipCode;
  }

  initializeCustomerType();

  if (activeTab.value) {
    activeTabRadios.value = generateCurrentTabProductGroups(activeTab.value.tariffs);
    activeTariffChoice.value = activeTabRadios.value[0];
  }

  // Check if we have a share link in the URL and restore it.
  if (Object.keys(route.query).length) {
    const decryptedShareLink = shareLinkDecryptor(
      new URLSearchParams(route.query as Record<string, string>),
    );
    if (Object.keys(decryptedShareLink).length) {
      restoreSearch(decryptedShareLink);
      fetchAndCalculate();
    }
  }
});

watch(customerType, () => {
  // Properly persist the customerType in the store (and therefore local storage)
  orderStore.customerType = customerType.value;
});

/** Every time the user switches the tab, the power consumption is
 * reset to 2400. This is problematic as it ignores the current value
 * in the input field.
 *
 * This function checks if there is an existing value in a form input
 * and sets the interal power consumption accordingly.
 *
 * @param fields is optional, so it doesn't interfere with the share
 * link feature.
 */
const updateInternalPowerConsumption = (fields?: any) => {
  if (!fields) return;

  if (fields.annualConsumptionPowerLow && fields.annualConsumptionPowerHigh) {
    setHighAndLowPowerConsumption(
      fields.annualConsumptionPowerHigh,
      fields.annualConsumptionPowerLow,
    );
  } else if (!fields.annualConsumptionPowerLow && fields.annualConsumptionPowerHigh) {
    setPowerConsumption(fields.annualConsumptionPowerHigh);
    setPowerConsumptionCompany(fields.annualConsumptionPowerHigh);
  }

  if (fields.annualConsumptionGas) {
    setGasConsumption(fields.annualConsumptionGas);
  }
};

const fetchAndCalculate = async (fields?: any) => {
  updateInternalPowerConsumption(fields);

  if (!props.isAdmin) {
    const isGasOverMaxConsumption = showGasConsumption.value > maxConsumption;
    const isPowerOverMaxConsumption = () => {
      if (customerType.value === CustomerType.Private) {
        return (
          showPowerHighConsumption.value +
            // TODO: Fix typings all over the place
            (parseInt(showPowerLowConsumption.value as unknown as string) || 0) >
          maxConsumption
        );
      } else {
        return (
          showPowerHighConsumptionCompany.value +
            (parseInt(showPowerLowConsumptionCompany.value as unknown as string) || 0) >
          maxConsumption
        );
      }
    };

    if (isGasOverMaxConsumption || isPowerOverMaxConsumption()) {
      modal.value?.show();
      return;
    }
  }

  isLoading.value = true;

  if (customerType.value === CustomerType.Private) {
    pickedPowerHighConsumption.value = showPowerHighConsumption.value;
    pickedPowerLowConsumption.value = showPowerLowConsumption.value;
  } else {
    pickedPowerHighConsumption.value = showPowerHighConsumptionCompany.value;
    pickedPowerLowConsumption.value = showPowerLowConsumptionCompany.value;
  }

  pickedGasConsumption.value = showGasConsumption.value;

  areResultsShown.value = false;

  try {
    // TODO: Can this happen?
    if (!activeTariffChoice.value) throw new Error("No active tariff choice");

    if (
      activeTariffChoice.value.value === "own" &&
      pickedPowerHighConsumption.value > ownTariffMaxConsumption
    ) {
      showOwnTariffQuotaMessage.value = true;
      hasErrors.value = false;
      isLoading.value = false;
      return;
    }

    if (!props.isAdmin) {
      const trackedTariffType =
        activeTariffChoice.value.value.charAt(0).toUpperCase() +
        activeTariffChoice.value.value.slice(1);
      useEmitFormSubmit("tariffCalculator" + trackedTariffType);
    }

    const generalPowerTariffs = await props.fetchTariffsCallback(
      "power",
      finalPostalCode.value,
      pickedPowerHighConsumption.value,
      props.calculator.partnerTariffId,
      pickedPowerLowConsumption.value || undefined,
      customerType.value,
    );

    const generalGasTariffs = await props.fetchTariffsCallback(
      "gas",
      finalPostalCode.value,
      pickedGasConsumption.value,
      props.calculator.partnerTariffId,
      undefined,
      customerType.value,
    );

    displayPowerTariffs.value = filterTariffs(
      // TODO remove the unknown cast as soon as the spec marks the `items` as required
      generalPowerTariffs.data.items as unknown as PublicTariff[] | AdminTariff[],
      activeTariffChoice.value,
      {
        consumption: pickedPowerHighConsumption.value,
        lowConsumption: pickedPowerLowConsumption.value,
        isAdmin: props.isAdmin,
        hasAdditionalMeter: additionalMeter.value,
        hasDoubleMeter: doubleMeter.value,
      },
    );

    const sortTariffs = (tariffs: PublicTariff[]) => {
      if (!activeTab.value) return;

      /** The order of the tariffs in the CMS is reflected by activeTab.value.tariffs. */
      sortTariffsByTariffTypes(tariffs, activeTab.value.tariffs);
    };

    // TODO: Actually filter for gas-filtered props.calculator.tariffs here
    displayGasTariffs.value = filterTariffs(
      // TODO remove the unknown cast as soon as the spec marks the `items` as required
      generalGasTariffs.data.items as unknown as PublicTariff[] | AdminTariff[],
      activeTariffChoice.value,
      {
        consumption: pickedPowerHighConsumption.value,
        lowConsumption: null,
        isAdmin: props.isAdmin,
        hasAdditionalMeter: additionalMeter.value,
        hasDoubleMeter: doubleMeter.value,
      },
    );

    sortTariffs(displayPowerTariffs.value);
    sortTariffs(displayGasTariffs.value);

    if (
      ((isDualBooking.value || isSingleGasBooking.value) &&
        displayGasTariffs.value?.length === 0) ||
      ((isDualBooking.value || isSinglePowerBooking.value) &&
        displayPowerTariffs.value?.length === 0)
    ) {
      hasErrors.value = true;
      isLoading.value = false;
      return;
    }
  } catch {
    hasErrors.value = true;
    isLoading.value = false;
    return;
  }

  orderStore.trackingInfo = {
    consumption: {
      powerHigh: showPowerHighConsumption.value,
      powerLow: showPowerLowConsumption.value,
      gas: showGasConsumption.value,
    },
    productGroup: activeTariffChoice.value?.value,
    tabTitle: activeTab.value?.title,
  };

  if (!props.isAdmin && activeTariffChoice.value) {
    useTrackViewItem({
      bookingType: customer ? "zubuchung" : "erstvertrag",
      consumption: orderStore.trackingInfo?.consumption,
      coupon: getReferralCode().code,
      customerType: customer.value?.customerType || customerType.value,
      postalCode: finalPostalCode.value,
      productGroup: orderStore.trackingInfo?.productGroup,
      tabTitle: orderStore.trackingInfo?.tabTitle || "Kein sichtbarer Tab",
      tariffs: isDualBooking.value
        ? [...displayPowerTariffs.value, ...displayGasTariffs.value]
        : isSinglePowerBooking.value
          ? displayPowerTariffs.value
          : displayGasTariffs.value,
      userLoggedIn: !!customer.value?.id,
    });
  }

  areResultsShown.value = true;
  hasErrors.value = false;
  showOwnTariffQuotaMessage.value = false;
  isLoading.value = false;

  setTimeout(() => {
    document.querySelector("#tarifliste")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, 100);
};

const chooseTariffAndAdvance = () => {
  // TODO: Save currentUrl to orderStore for back button in 2nd step
  orderStore.trackingInfo = {
    consumption: {
      powerHigh: showPowerHighConsumption.value,
      powerLow: showPowerLowConsumption.value,
      gas: showGasConsumption.value,
    },
    productGroup: activeTariffChoice.value?.value,
    tabTitle: activeTab.value?.title,
  };

  orderStore.customerType = customerType.value;
  orderStore.newCustomerZipCode = finalPostalCode.value || "";

  if (displayPowerTariffs.value) {
    orderStore.cachedPowerTariffs = displayPowerTariffs.value;
  }
  if (displayGasTariffs.value) {
    orderStore.cachedGasTariffs = displayGasTariffs.value;
  }

  if (isSinglePowerBooking.value && selectedPowerContract.value) {
    orderStore.setNewContracts([selectedPowerContract.value]);
  }
  if (isSingleGasBooking.value && selectedGasContract.value) {
    orderStore.setNewContracts([selectedGasContract.value]);
  }
  if (isDualBooking.value && selectedPowerContract.value && selectedGasContract.value) {
    orderStore.setNewContracts([selectedPowerContract.value, selectedGasContract.value]);
  }

  /** Since the user can leave the contract transfer or the tenant power registration
   * at any time we need to reset the values here so it's not feeded into
   * the next calculation.
   */
  orderStore.resetSpecialCheckoutFlows();

  if (!props.isAdmin && activeTariffChoice.value) {
    useTrackChooseAndAdvance({
      bookingType: customer ? "zubuchung" : "erstvertrag",
      consumption: {
        powerHigh: showPowerHighConsumption.value,
        powerLow: showPowerLowConsumption.value,
        gas: showGasConsumption.value,
      },
      coupon: getReferralCode().code,
      customerType:
        customer.value?.customerType || props.calculator.businessCustomerPreSelected
          ? CustomerType.Company
          : CustomerType.Private,
      newContracts: orderStore.newContracts,
      postalCode: finalPostalCode.value,
      productGroup: orderStore.trackingInfo?.productGroup,
      tabTitle: orderStore.trackingInfo?.tabTitle || "Kein sichtbarer Tab",
      userLoggedIn: !!customer.value?.id,
    });
  }
  navigateTo(props.advanceUrl);
};

/**
 * Generates the share to the current search and copies it to the clipboard.
 */
const copyShareLink = () => {
  const searchQueryParams = shareLinkShortener({
    finalPostalCode: finalPostalCode.value,
    pickedGasConsumption: pickedGasConsumption.value,
    pickedPowerHighConsumption: pickedPowerHighConsumption.value,
    pickedPowerLowConsumption: pickedPowerLowConsumption.value,
    activeTariffChoice: activeTariffChoice.value?.value,
    activeTabIndex: displayTabs.value.findIndex(
      (t) => t.title === activeTab.value?.title,
    ),
    doubleMeter: doubleMeter.value,
    additionalMeter: additionalMeter.value,
    customerType: customerType.value,
  });

  let baseUrl = window.location.origin + window.location.pathname;

  // If we are on the admin or customer area page,
  // we need to change the baseUrl to the public calculator page
  if (baseUrl.includes(adminAreaPrefix) || baseUrl.includes(customerAreaPrefix)) {
    baseUrl = $config.public.baseUrl + config.routes.calculatorPage;
  }

  copy(baseUrl + "?" + searchQueryParams);
};

/**
 * Restores the search from the URL query parameters into the state.
 * @param search
 */
const restoreSearch = (search: Partial<TariffCalculatorShareLinkAttributes>) => {
  if (typeof search.activeTabIndex !== "undefined") {
    initialTabIndex.value = search.activeTabIndex;
    const tab = displayTabs.value[search.activeTabIndex];
    setActiveTab(tab);
  }
  if (search.finalPostalCode) {
    finalPostalCode.value = search.finalPostalCode;
  }
  if (search.pickedGasConsumption) {
    showGasConsumption.value = search.pickedGasConsumption;
    pickedGasConsumption.value = search.pickedGasConsumption;
  }
  if (search.customerType) {
    customerType.value = search.customerType;
  }
  if (search.pickedPowerHighConsumption) {
    if (customerType.value === CustomerType.Private) {
      showPowerHighConsumption.value = search.pickedPowerHighConsumption;
    } else {
      showPowerHighConsumptionCompany.value = search.pickedPowerHighConsumption;
    }
    pickedPowerHighConsumption.value = search.pickedPowerHighConsumption;
  }
  if (search.pickedPowerLowConsumption) {
    showPowerLowConsumption.value = search.pickedPowerLowConsumption;
    pickedPowerLowConsumption.value = search.pickedPowerLowConsumption;
  }
  if (search.doubleMeter) {
    doubleMeter.value = search.doubleMeter;
  }
  if (search.additionalMeter) {
    additionalMeter.value = search.additionalMeter;
  }
  if (search.activeTariffChoice && activeTab.value) {
    const availableProductGroups = generateCurrentTabProductGroups(
      activeTab.value.tariffs,
    );
    activeTariffChoice.value = availableProductGroups.find(
      (p) => p.value === search.activeTariffChoice,
    );
  }
};
</script>

<style lang="scss">
.tariff-calculator {
  input {
    background: var(--ps-white);
  }

  .business-toggle {
    margin: 0.75rem 0;
    // Break on where small screens
    flex-wrap: wrap;
  }

  [data-type="checkbox"] .formkit-input ~ .formkit-decorator,
  [data-type="radio"] .formkit-input ~ .formkit-decorator {
    background: var(--background);
    box-shadow: 0 0 0 1px var(--foreground);
  }
}

.tab-radio-customer-type--wrapper {
  display: flex;
  justify-content: space-between;
}

.show-and-pick-content .headline {
  white-space: nowrap;
}

.show-and-pick-content {
  display: flex;
  gap: 1.75rem;
  @include breakpoint-from(medium) {
    gap: 3rem;
  }

  .formkit-input,
  input {
    background-color: var(--background);
    color: var(--foreground);
    border: var(--border-base) var(--input--teal-border-color);
    border-width: 0 0 var(--hairline);
  }

  .left {
    white-space: nowrap;
  }
  .center {
    width: 50%;
  }
  .right {
    width: 30%;
    margin-left: auto;
    padding-top: 2.5rem;

    button {
      width: 100%;
    }
  }

  &.dual-booking {
    .center,
    .right {
      width: 50%;
    }

    .right {
      padding-top: 0;
      margin-left: unset;
    }
  }
}

@container (max-width: #{$screen-sm}) {
  .tab-radio-customer-type--wrapper {
    flex-direction: column;
  }
  .tab-radio--wrapper {
    order: 2;
  }
  .customer-type--toggle {
    display: flex;
    justify-content: flex-end;
    order: 1;
  }
  .show-and-pick-content {
    flex-direction: column;
    gap: 0;

    .center {
      width: 100%;
    }

    .right {
      width: 75%;
      margin-right: auto;
      padding-top: 0;

      button {
        width: fit-content;
      }
    }

    &.dual-booking {
      .center,
      .right {
        width: 100%;
      }
    }
  }
}
</style>
